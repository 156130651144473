// card
.card {
  .card-header,
  .card-body,
  .card-footer {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

// dropdown
.dropdown[data-popper-reference-hidden] {
  pointer-events: none;
  visibility: hidden;
}

.dropdown-toggle:not(.dropdown-toggle-split)::after {
  display: none !important;
}

// form
.form-control {
  &[readonly] {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    opacity: 1;
  }

  &::placeholder {
    color: #999;
  }
}

// nav & tab
.tab-pane {
  overflow: hidden;

  &.active {
    display: flex;
    flex-direction: column;
  }
}

// ng-select
.ng-select.custom {
  @include border-radius($form-select-border-radius, 0);

  > .ng-select-container {
    min-height: $input-height;
    color: $form-select-color;
    background-color: $form-select-bg;
    border: $form-select-border-width solid $form-select-border-color;
    border-radius: inherit;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:focus,
    &:focus-within {
      box-shadow: 0 0 0 0.25rem var(--bs-focus-ring-color);
    }
  }

  &.ng-select-disabled > .ng-select-container {
    background-color: $form-select-disabled-bg;
  }

  &.is-invalid {
    .ng-select-container {
      align-items: center;
      min-height: 36px;
      background-color: #f8d7da;
      border: 1px solid var(--bs-form-invalid-border-color);
      border-radius: 4px;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      .ng-value-container .ng-placeholder {
        color: var(--bs-gray);
      }

      &:focus,
      &:focus-within {
        box-shadow: 0 0 0 0.25rem
          rgba($color: var(--bs-danger-rgb), $alpha: 25%);
      }
    }
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $form-select-focus-border-color;
    outline: 0;

    @if $enable-shadows {
      @include box-shadow(
        $form-select-box-shadow,
        $form-select-focus-box-shadow
      );
    } @else {
      box-shadow: $form-select-focus-box-shadow;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    &.ng-option-disabled {
      color: var(--bs-form-invalid-color);
    }

    &.ng-option-marked {
      background-color: var(--bs-primary-bg-subtle);
    }

    &.ng-option-selected {
      background-color: var(--bs-secondary-bg-subtle);
    }
  }
}

.input-group > .ng-select.custom {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;

  &.ng-select-focused:not(.ng-select-opened) {
    z-index: 5;
  }
}

.ng-dropdown-panel {
  z-index: 1055 !important;
}

@mixin custom-select-validation-state(
  $state,
  $color,
  $icon,
  $tooltip-color,
  $tooltip-bg-color,
  $focus-box-shadow,
  $border-color
) {
  .ng-select.custom {
    @include form-validation-state-selector($state) {
      > .ng-select-container {
        border-color: $color;
      }

      &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
      }
    }
  }
}

@each $state, $data in $form-validation-states {
  @include custom-select-validation-state($state, $data...);
}
